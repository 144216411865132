import React from 'react';

import {
  Root,
  Container,
  Title,
  TitleImg,
  TitleText,
  Item,
  ItemImg,
  ItemTitle,
  ItemDesc,
  More,
  Signature
} from './OurHistory.style';

import StarsSrc from '../../images/stars.svg';
import Item1Src from '../../images/our-history-item-1.jpg';
import Item2Src from '../../images/our-history-item-2.jpg';
import Item3Src from '../../images/our-history-item-3.jpg';
import Item4Src from '../../images/our-history-item-4.png';
import SignatureSrc from "../../images/joe-baker-signature.svg";

const OurHistory = () => {
  return (
    <Root>
      <Container>
        <Title>
          <TitleImg src={StarsSrc} alt="" />
          <TitleText>OUR HISTORY</TitleText>
        </Title>

        <Item className="--item1">
          <ItemImg src={Item1Src} alt="" />
          <ItemTitle>
            NAMED TO HONOR THE&nbsp;FOUNDING FATHER OF MY FAMILY
          </ItemTitle>
          <ItemDesc style={{marginLeft: '0'}}>
            One of the original settlers of Tennessee - who beat back the British in the Battle
            of Kings Mountain and faithfully fought for freedom as an Overmountain Man in the Revolutionary War,
            this treasured family secret is now my pleasure to share with you.
            <br />
            <Signature src={SignatureSrc} alt="" />
            <br />
            <span style={{color: 'white'}}>Joe Baker, Founder of Ole Smoky Distillery</span>
            <br />
            <span style={{color: 'white'}}>James Ownby's 5th Great Grandson</span>
          </ItemDesc>
        </Item>

        {/* <Item className="--item1">
          <ItemImg src={Item2Src} alt="" />
          <ItemTitle>
            NAMED TO HONOR THE FOUNDING FATHER OF MY FAMILY
          </ItemTitle>
          <ItemDesc>
            One of the original settlers of Tennessee - who beat back the British in the Battle
            of Kings Mountain and faithfully fought for freedom as an Overmountain Man in the Revolutionary War,
            this treasured family secret is now my pleasure to share with you.
            <Signature src={SignatureSrc} alt="" />
            <br />
            <span style={{color: 'white'}}>Joe Baker, Founder of Ole Smoky Distillery</span>
            <br />
            <span style={{color: 'white'}}>James Ownby's 5th Great Grandson</span>
          </ItemDesc>
        </Item> */}
{/*
        <Item className="--item2">
          <ItemImg src={Item2Src} alt="" />
          <ItemTitle>
            THIS TREASURED FAMILY SECRET IS NOW OUR PLEASURE TO SHARE WITH YOU
          </ItemTitle>
        </Item>

        <Item className="--item3">
          <ItemImg src={Item3Src} alt="" />
          <ItemDesc>
            So as we lift our glasses to the man who started it all, we honor
            his memory by memorializing the man in the following poem.
          </ItemDesc>
        </Item> */}

        {/* <Item className="--item2">
          <ItemImg src={Item2Src} alt="" />
          <ItemTitle>
            THIS TREASURED FAMILY SECRET IS NOW OUR PLEASURE TO SHARE WITH YOU
          </ItemTitle>
        </Item>

        <Item className="--item3">
          <ItemImg src={Item3Src} alt="" />
          <ItemDesc>
            So as we lift our glasses to the man who started it all, we honor
            his memory by memorializing the man in the following poem.
          </ItemDesc>
        </Item>
        <Item className="--item4">
          <ItemImg src={Item4Src} alt="" />
          <ItemTitle>
            JAMES OWNBY: THE&nbsp;LEGEND AND REVOLUTIONARY WAR HERO
            <br />
            <More href="#poem">Read more</More>
          </ItemTitle>
        </Item> */}
      </Container>
    </Root>
  );
};

export default OurHistory;
