import styled from 'styled-components';

import GlassSrc from '../../images/glasswhiskey.png';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 24px;
  padding-left: 24px;
  background-color: #e9dfd0;
  background-image: url(${GlassSrc});
  background-repeat: no-repeat;

  @media (max-width: 1023px) {
    padding-top: 60px;
    padding-bottom: 60px;
    background-position: 100% 180px;
    background-size: auto 260px;
  }

  @media (min-width: 1024px) {
    height: 1260px;
    padding-top: 110px;
    padding-bottom: 82px;
    background-position-x: calc(100% + 100px);
    background-position-y: 97%;
    background-size: auto 358px;
  }

  @media (min-width: 1200px) {
    background-position: 100% 97%;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1185px;

  @media (min-width: 1024px) {
    padding-right: 39px;
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  right: 0;
  width: 39px;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const HeadingImg = styled.img`
  width: 39px;
`;

export const HeadingText = styled.div`
  margin-top: 26px;
  padding-left: 17px;
  color: #d8993f;
  font-family: 'GreatWestern';
  font-size: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-left: 1px solid #d8993f;
  transform: rotate(90deg);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Img = styled.img`
  width: 327px;
  margin-right: 40px;
  border-radius: 50%;
`;

export const Title = styled.h3`
  max-width: 640px;
  color: #141f35;
  font-family: 'GreatWestern';
  letter-spacing: 0.05em;

  @media (max-width: 639px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: 1023px) {
    margin-top: 64px;
  }

  @media (min-width: 640px) {
    font-size: 30px;
    line-height: 34px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 38px;
  }

  @media (min-width: 1200px) {
    font-size: 44px;
    line-height: 51px;
  }
`;

export const Date = styled.div`
  color: #d1914c;
  font-family: 'DharmaGothic';
  letter-spacing: 0.13em;
  line-height: 35px;

  @media (max-width: 1023px) {
    font-size: 28px;
  }

  @media (min-width: 1024px) {
    font-size: 34px;
  }
`;

export const Body = styled.div`
  display: flex;
  max-width: 976px;
  margin-top: 60px;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    margin-left: 40px;
  }
`;

export const Column = styled.div`
  color: #141f35;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 640px;
    font-size: 15px;
    line-height: 24px;
  }

  @media (min-width: 1024px) {
    width: calc(50% - 45px);
    font-size: 18px;
    line-height: 30px;
  }
`;

export const BodyTitle = styled.h4`
  margin: 0;
  color: #d1914c;
  font-family: 'GreatWestern';

  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 40px;
  }
`;
