import React, { useState } from "react";

import { Root, Container, Body, Title, Text, Button, BookImg } from "./HistoryPoem.style";

import BookSrc from '../../images/book.png';

import ModalPoem from '../ModalPoem/ModalPoem';

const HistoryPoem = () => {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <Root id="poem">
      <Container>
        <Body>
          <Title>A Legacy of Renegades</Title>
          <Text>
            Our collective story told through poetry to express the incredible life of this heroic man and the legacy that carried through the generations to inspire the bourbon named in his honor.
          </Text>
          <Button onClick={() => setIsShowModal(true)}>Read Poem</Button>
        </Body>
        <BookImg src={BookSrc} alt="" onClick={() => setIsShowModal(true)} />
      </Container>

      {isShowModal && <ModalPoem onClose={() => setIsShowModal(false)} />}
    </Root>
  );
};

export default HistoryPoem;
