import React from 'react';
import { Portal } from "react-portal";

import {
  Root,
  Dialog,
  Content,
  Frame,
  Body,
  Container,
  Title,
  TextBody,
  Text,
  Close,
} from "./ModalPoem.style";

import useLockBodyScroll from "../../hooks/useLockBodyScroll";

const ModalPoem = ({ onClose }) => {
  useLockBodyScroll();

  return (
    <Portal>
    <Root
        tabIndex={-1}
        role="dialog"
        aria-modal
        onClick={onClose}
      >
      <Dialog>
        <Content>
          <Frame />
          <Body>
            <Container>
              <Title>A Legacy of Renegades</Title>
              <TextBody>
                <Text>
                  <span>
                    Lost amongst the ages
                    <br />
                    Lies a story seldom told
                    <br />
                    Of an ancient family secret
                    <br />
                    Near a lonesome oak filled grove
                  </span>
                  <span>
                    A revolutionary soldier
                    <br />
                    A rebel and his still
                    <br />
                    An Overmountain Man, a legend
                    <br />
                    Making whiskey for the thrill
                  </span>
                  <span>
                    Under moonlight, in the Smokies
                    <br />
                    By the dancing light of stars
                    <br />
                    Lived a man by name of Ownby
                    <br />
                    Who raised a glass and raised the bar
                  </span>
                  <span>
                    Though the law made him an outlaw
                    <br />
                    True to his sacred craft he stayed
                    <br />
                    Handing down family tradition
                    <br />A legacy of renegades
                  </span>
                  <span>
                    Moonshiners and makers
                    <br />
                    Each and every one so bold
                    <br />
                    Generations by the barrel
                    <br />
                    Broke the law and broke the mold
                  </span>
                  <span>
                    Defying all convention
                    <br />
                    His sacrifice endures
                    <br />
                    Today his treasured spirits
                    <br />
                    Pass from our good hands to yours
                  </span>
                  <span>
                    Though of you we ask one favor
                    <br />
                    Respect and honor as you taste
                    <br />
                    The life’s work of old man Ownby
                    <br />
                    Please take your time, no time to waste
                  </span>
                </Text>
              </TextBody>
            </Container>
          </Body>
          <Close onClick={onClose}>Close</Close>
        </Content>
      </Dialog>
    </Root>
    </Portal>
  )
}

export default ModalPoem
