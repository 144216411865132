import styled from 'styled-components';

// import BgSrc from '../../images/blue-noise-bgOLD.png';
import BgSrc from '../../images/our-history-bg-desktopOLD.jpg';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding-right: 24px;
  padding-left: 24px;
  background-color: #393b4e;
  background-image: url(${BgSrc});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;

  @media (max-width: 1023px) {
    padding-top: 52px;
    padding-bottom: 24px;
  }

  @media (min-width: 1024px) {
    height: 720px;
  }

  ${'' /* @media (min-width: 1300px) {
    height: 890px;
  } */}
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1185px;

  @media (max-width: 1023px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;

export const Body = styled.div`
  flex-shrink: 0;
  width: 100%;
  max-width: 465px;
`;

export const Title = styled.h3`
  display: inline-block;
  max-width: 80%;
  margin-bottom: 16px;
  color: #d1914c;
  font-family: 'GreatWestern';
  text-transform: uppercase;

  @media (max-width: 639px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media (min-width: 640px) {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.05em;
  }
`;

export const Text = styled.p`
  color: #f3efe8;
  font-size: 18px;
  line-height: 30px;
`;

export const Button = styled.button`
  width: 132px;
  height: 48px;
  margin-top: 24px;
  color: #ce9b61;
  font-family: 'DharmaGothic';
  font-size: 30px;
  letter-spacing: 0.05em;
  line-height: 50px;
  text-transform: uppercase;
  border: 1px solid #ce9b61;
`;

export const BookImg = styled.img`
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 640px;
  }

  @media (min-width: 1024px) {
    position: absolute;
    right: -20%;
    width: 76%;
    max-width: 1152px;
    height: auto;
  }

  @media (min-width: 1628px) {
    right: -10%;
  }

  @media (min-width: 1780px) {
    right: -5%;
  }

  @media (min-width: 1900px) {
    position: relative;
    right: auto;
  }
`;
