import styled, { css, keyframes } from "styled-components";

import FrameSrc from '../../images/frame-bgOLD.png';
import BgSrc from '../../images/blue-noise-bgOLD.png';

const framePseudo = css`
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    background-image: url(${FrameSrc});
    background-repeat: no-repeat;

    @media (max-width: 1299px) {
      background-size: 70%;
    }

    @media (min-width: 1300px) {
      background-size: 429px 280px;
    }
  }
`;

export const Root = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 41, 41, 0.7);
  outline: 0;
  z-index: 7000;
  backdrop-filter: blur(3px);

  @media (min-width: 1024px) and (max-height: 534px){
    padding: 48px 0;
    overflow-y: auto;
  }

  @media (min-width: 1024px) and (min-height: 535px) {
    align-items: center;
  }
`;

export const Dialog = styled.div`
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1024px) {
    width: 90%;
    max-width: 1300px;
    height: 90vh;
    margin: 0 auto;
    overflow: hidden;
    pointer-events: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #393b4e;
  background-image: url(${BgSrc});
  outline: 0;
  pointer-events: all;


  @media (max-width: 1023px) {
    justify-content: center;
  }

  ${framePseudo}

  &::before {
    top: 15px;
    left: 20px;
  }

  &::after {
    top: 15px;
    right: 20px;
    transform: scaleX(-1);
  }
`;

export const Frame = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${framePseudo}

  &::before {
    bottom: 15px;
    left: 20px;
    transform: scaleY(-1);
  }

  &::after {
    bottom: 15px;
    right: 20px;
    transform: scale(-1, -1);
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 1185px;
  height: 100%;
  max-height: 100%;
  /* padding-bottom: 82px; */
  text-align: center;

  @media (max-width: 767px) {
    padding: 96px 0;
  }

  @media (min-width: 768px) {
    padding: 120px 0;
  }
`;

export const Title = styled.h3`
  display: inline-block;
  max-width: 80%;
  margin-bottom: 16px;
  color: #d1914c;
  font-family: 'GrocersScript';
  text-align: center;

  @media (max-width: 767px) {
    font-size: 54px;
    line-height: 48px;
  }

  @media (min-width: 768px) {
    font-size: 64px;
  }

  @media (min-width: 1024px) {
    font-size: 78px;
    line-height: 100px;
  }

  @media (min-width: 1300px) {
    font-size: 96px;
    line-height: 140px;
  }
`;

export const TextBody = styled.div`
  padding: 0 24px;
  overflow-y: auto;
`;

export const Text = styled.p`
  color: #f3efe8;
  font-family: 'DharmaGothic';
  letter-spacing: 0.05em;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 40px;
    line-height: 40px;
  }

  span {
    display: block;

    @media (max-width: 767px) {
      margin-bottom: 16px;
    }

    @media (min-width: 768px) {
      margin-bottom: 24px;
    }

    @media (min-width: 1024px) {
      margin-bottom: 48px;
    }
  }
`;

export const Close = styled.button`
  position: absolute;
  left: 50%;
  color: #d1914c;
  font-family: 'DharmaGothic';
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transform: translateX(-50%);

  @media (max-width: 767px) {
    bottom: 12px;
    font-size: 20px;
  }

  @media (min-width: 768px) {
    bottom: 16px;
    font-size: 28px;
  }
`;
