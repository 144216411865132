import React from "react";

import {
  Root,
  Container,
  // Heading,
  // HeadingImg,
  // HeadingText,
  Header,
  Img,
  Title,
  Date,
  Body,
  Column,
  BodyTitle,
} from "./HeroHistory.style";

// import LogoMinSrc from "../../images/logo-min.svg";
import SoldierSrc from "../../images/soldier.jpg";

const HeroHistory = () => {
  return (
    <Root>
      <Container>
        {/* <Heading>
          <HeadingImg src={LogoMinSrc} alt="" />
          <HeadingText>
            our&nbsp;history
          </HeadingText>
        </Heading> */}

        <Header>
          <Img src={SoldierSrc} alt="" />
          <div>
            <Title>
              JAMES OWNBY: THE&nbsp;LEGEND AND REVOLUTIONARY WAR HERO
            </Title>
            <Date>06 JAN 1761 - 26 SEP 1850</Date>
          </div>
        </Header>

        <Body>
          <Column>
            <BodyTitle>MOST MEN LIVE LIVES OF QUIET DESPERATION,</BodyTitle>
            <br />
            <span>
              but James Ownby was not most men. He lived a life so epic we still
              tell his tale to this day – usually around a bonfire, and always
              around a bottle. You see before there was an ‘America’, or a land
              called ‘Tennessee’, there was man, named James Ownby, who braved
              the unsettled frontier and forged an enduring family legacy. As a
              soldier, he was dedicated. Enlisting at the age of 17, and serving
              6 tours of duty throughout his esteemed military career, James
              fearlessly fought for our young country‘s freedom, for his beliefs
              and for a better way of life. His most notable service came as a
              member of the renowned ‘Overmountain Men’ regiment under the
              command of Colonel John Sevier in the Battle of Kings Mountain – a
              battle which Thomas Jefferson fittingly deemed "the turn of the
              tide of success."
            </span>
          </Column>
          <Column>
            <span>
              As a pioneer, he was prolific. James set down roots in the area
              surrounding the Great Smoky Mountains and built a life for himself
              and his family. The Ownby’s became known as one of the original
              five families who significantly settled in the state we now call
              Tennessee and the acorns from his family tree have become a mighty
              grove of oaks across the once wild land.
            </span>
            <br />
            <br />
            <BodyTitle>AS A LEGEND, HE LIVES ON.</BodyTitle>
            <br />
            <span>
              Today we still point to James Ownby as our family’s founding
              father, who defended our time-honored traditions by taking a path
              that certainly was not easy, but the only way he knew to be right.
              So as we lift our glasses to the man who started it all, we honor
              his memory by memorializing the man in the following poem. Please
              use it wisely and pass it on respectfully as you proudly proclaim
              it from the tops of the Ole Smoky Mountains.
            </span>
          </Column>
        </Body>
      </Container>
    </Root>
  );
};

export default HeroHistory;
